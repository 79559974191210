import React from "react";
import CTAMenuLink from "gatsby-theme-starberry-lomondgroup/src/modules/cta_menu_link_module"

const FooterLinks = ({data}) => {

    return (

        <div className="inner-wrap">
            <span className="sm-text">
                {data?.menu_link?.map((item, index) => {
                    
                    return(
                        <>
                            <CTAMenuLink item={item} /> 
                            {(data?.menu_link?.length - 1) > index  ? <span> / </span> : ""}
                        </>
                        
                    )
                })}

                <br />
                © Copyright {new Date().getFullYear()} Lomond. All Rights Reserved.
            </span>
            <br className="d-lg-none"></br>
        </div>
    )
}
export default FooterLinks;