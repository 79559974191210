import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Navbar} from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"

import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module.js";


// Images

const HeaderLogo = (props) => {

  const data = useStaticQuery(graphql`
  query HeaderLogoQueryTheme{
    glstrapi {

      siteConfig {
        Header_Logo {
          url
          alternativeText
        }
        Header_Bg_White_Logo{
          url
          alternativeText
        }
        investment_logo {
          alternativeText
          url
        }
      }
    }
  }`)

  var header_data = data.glstrapi;

  //console.log("menu_data", props?.GQLPage?.menu?.slug)

  let menu_slug_lim = ["investment-management", "rental-properties-available", "cambridge", "trumpington", "darwin-green", "franklin-gardens", "oughtibridge-valley", "wendel-view"]

  //console.log("menu_data", props?.GQLPage?.menu?.slug, menu_slug_lim.filter(item => item === props?.GQLPage?.menu?.slug), menu_slug_lim.includes(props?.GQLPage?.menu?.slug))

  return (
    <React.Fragment>
      <Navbar.Brand>
        <Link to="/">
          {(props?.GQLPage?.layout === "landing_template" || props?.GQLPage?.layout === "static_template" || props?.GQLPage?.layout === "team_template") ? (
            ( menu_slug_lim.includes(props?.GQLPage?.menu?.slug)) ?
              <ImageModule ImageSrc={header_data?.siteConfig?.investment_logo ? header_data?.siteConfig?.investment_logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} classNames="logo"/>
            :
              <ImageModule ImageSrc={header_data?.siteConfig?.Header_Logo} altText={"Logo"} classNames="logo"/>
            )
          :
            props.scroll ?
              (props?.GQLPage?.menu?.slug === "tenancy-offer") ?
              <ImageModule ImageSrc={header_data?.siteConfig?.investment_logo ? header_data?.siteConfig?.investment_logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} classNames="logo"/>
              :
              <ImageModule ImageSrc={header_data?.siteConfig?.Header_Logo} altText={"Logo"} classNames={`logo sticky_logo ${props.scroll}`}/>
              
            : (
              (props?.GQLPage?.menu?.slug === "tenancy-offer") ?
              <ImageModule ImageSrc={header_data?.siteConfig?.investment_logo ? header_data?.siteConfig?.investment_logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} classNames="logo"/>
            :
              <ImageModule ImageSrc={header_data?.siteConfig?.Header_Bg_White_Logo ? header_data?.siteConfig?.Header_Bg_White_Logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} classNames={`logo ${props.scroll}`} />
            )
          }                      
        </Link>
      </Navbar.Brand>
    </React.Fragment>
  )
}



export default HeaderLogo